<template>
    <div class="trade-platform-stats-widget">
        <div class="col-12 d-flex align-items-center mb-2">
            <b-button variant="light" class="mr-sm-2" @click="onExport">
                Export All
            </b-button>
            <date-range-picker v-model="dateRange" opens="left" single-date-picker :ranges="false" class="mr-2 align-self-right" @update="requests">
                        <template v-slot:input="picker" style="min-width: 350px;">
                            <span>{{ picker.startDate | date('MMM dd, yyyy') }}</span>
                            <i class="mdi mdi-calendar-blank" style="padding-left: 7px;"></i>
                        </template>
            </date-range-picker>
         </div>
         <div v-if="chart1Data.data.length > 0" class="col-6 float-left">
             <histogram @getSVG="getSVG"  :chartID="chartIDs[0]" :title="chartTitles[0]" :data="chart1Data" daily></histogram>
             <b-table v-if="ID === null" :items="tableOptions.items[0]" :fields="tableOptions.dailyColumns" responsive class="text-center"
                    show-empty>
                    <template slot="empty">
                        No results found
                    </template>
                </b-table>
         </div>

         <div v-if="chart1Data.data.length > 0" class="col-6 float-left">
             <histogram @getSVG="getSVG" :chartID="chartIDs[2]" :title="chartTitles[2]" :data="chart3Data" :winRate="true" daily></histogram>
                <b-table v-if="ID === null" :items="tableOptions.items[1]" :fields="tableOptions.dailyColumns" responsive class="text-center"
                    show-empty>
                    <template slot="empty">
                        No results found
                    </template>
                </b-table>
         </div>
         <div v-if="chart1Data.data.length > 0" class="col-6 float-left">
             <histogram @getSVG="getSVG"  :chartID="chartIDs[1]" :title="chartTitles[1]" :data="chart2Data"></histogram>
             <b-table v-if="ID === null" :items="tableOptions.items[2]" :fields="tableOptions.weeklyColumns" responsive class="text-center"
                    show-empty>
                    <template slot="empty">
                        No results found
                    </template>
                </b-table>
         </div>
         <div v-if="chart1Data.data.length > 0" class="col-6 float-left">
             <histogram @getSVG="getSVG" :chartID="chartIDs[3]" :title="chartTitles[3]" :data="chart4Data" :winRate="true"></histogram>
             <b-table v-if="ID === null" :items="tableOptions.items[3]" :fields="tableOptions.weeklyColumns" responsive class="text-center"
                    show-empty>
                    <template slot="empty">
                        No results found
                    </template>
                </b-table>
         </div>
    </div>
</template>

<script>
import DateRangePicker from 'vue-daterange-picker-light';
import { addDays, compareAsc, subBusinessDays, isToday, startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears } from 'date-fns';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';
import { getColor } from '@/lib/ColorLookup';
import { exportCsv, exportChartWithText } from '@/lib/Exporting';
import histogram from "@/widgets/histogram"
import {resolveDay} from '@/lib/Helpers'

export default {
    name: 'trade-platform-stats',
    data() {
        let defaultFormatter = function(val){
            if(val ==null){
                return "-"
            }else{
                return Number(val).toFixed(2) + "%"
            }
            
        }
        let percentileFormatter = function(val){
            if(val ==null){
                return "-"
            }else{
                return (Number(val)).toFixed(2) + "%"
            }
            
        }

        let startDate = this.startDate;
        if(startDate === null) {
            startDate = new Date(Date.now());
        } else if(startDate.getDay() === 6) {
            startDate = addDays(startDate, 1);
        }


        return {
            dateRange: {
                startDate: startDate,
            },
            exportData: [
                {id:"0",scaleX:1,scaleY:1,split:false,enabled:true},
                {id:"1",scaleX:1,scaleY:1,split:false,enabled:true},
                {id:"2",scaleX:1,scaleY:1,split:false,enabled:true},
                {id:"3",scaleX:1,scaleY:1,split:false,enabled:true}
            ],
            chart1Data:{data:[]},
            chart2Data:{data:[]},
            chart3Data:{data:[]},
            chart4Data:{data:[]},

            tableOptions:{
                dailyColumns:[{key:"Monday",value:"Mon",formatter:defaultFormatter}, {key:"Tuesday",value:"Tues",formatter:defaultFormatter}, {key:"Wednesday",value:"Wed",formatter:defaultFormatter}, {key:"Thursday",value:"Thur",formatter:defaultFormatter}, {key:"Friday",value:"Fri",formatter:defaultFormatter}],
                weeklyColumns:[{key:"WTD",value:"WTD",formatter:defaultFormatter}, {key:"Percentile",value:"Percentile",formatter:percentileFormatter}],
                items:[[],[],[],[]]
            }
            
        };
    },
    components:{
        DateRangePicker,
        histogram
    },
    props:{
        chartTitles:{
            type:Array,
            default:()=>{return ["Daily Return","Weekly Return","Daily Component Win Rate","Weekly Component Win Rate"]}
        },
        chartIDs:{
            type:Array,
            default:()=>{return ["0","1","2","3"]}
        },
        ID:{
            type:Number,
            default: null
        },
        startDate: {
            type: Date,
            default: null
        }
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('optimiserreturns', this.onResponse);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('optimiserreturns', this.onResponse);

                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.chart1Data = {data:[]};
            this.chart2Data = {data:[]};
            this.chart3Data = {data:[]};
            this.chart4Data = {data:[]};

            this.tableOptions.items = [[],[],[],[]];

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'OptimiserReturns',
                args: {
                    Date: format(this.dateRange.startDate, 'yyyy-MM-dd'),
                }
            });
        },
        onResponse(event) {
           let daily = event.response.daily
           let weekly = event.response.weekly
           let summary = event.response.summary
           let winRateWeekly = event.response.winRateWeekly
           let winRateDaily = event.response.winRateDaily
            
           //returns
           let dailyArray = Object.keys(daily).map(key => {
                return daily[key];
            })
            let weeklyArray = Object.keys(weekly).map(key => {
                return weekly[key];
            })
           
            //components
            let dailyComponent = this.getComponentData(dailyArray.map(x=>x.ComponentData));
            let weeklyComponent = this.getComponentData(weeklyArray.map(x=>x.ComponentData));

            //box data
            let summaryResponse = {};
            let sum = 0;
            for(let item in summary){
                let summaryReturn = (summary[item].TotalReturn*100);
                summaryResponse[resolveDay(item)] = summaryReturn;
                sum += summaryReturn
            }

            //set chart data
            this.chart1Data.data = (dailyArray.map(x=>x.TotalReturn));
            this.chart2Data.data = (weeklyArray.map(x=>x.TotalReturn));
            this.chart3Data.data = (dailyComponent);
            this.chart4Data.data = (weeklyComponent);

            //percentile
            let tempArray = [...this.chart2Data.data]
            tempArray.push(sum);
            tempArray.sort(function(a, b){return a-b});
            let percentileReturn = tempArray.indexOf(sum) / tempArray.length ;
            let percentileWinRate = 0;
            if(Object.values(winRateWeekly).length > 0){
                tempArray = [...this.chart4Data.data]
                Object.values(winRateWeekly)[0].TotalReturn *= 100
                let tempVal = Object.values(winRateWeekly)[0].TotalReturn
                tempArray.push(tempVal)
                tempArray.sort(function(a, b){return a-b});
                percentileWinRate = tempArray.indexOf(tempVal) /tempArray.length
            }

            if(Object.values(winRateWeekly).length > 0){
                this.chart4Data.boxData = [Object.values(winRateWeekly)[0].TotalReturn,percentileWinRate*100]
            }

             //set box data
            this.chart1Data.boxData = summaryResponse
            this.chart2Data.boxData = [sum, percentileReturn*100]
            this.chart3Data.boxData = {}

            for(let date in winRateDaily){
                let key = format(new Date(date), "EEEE")
                let positiveCount = winRateDaily[date].TotalReturn;
                let count = winRateDaily[date].Count;
                this.chart3Data.boxData[key] = (positiveCount / count) * 100;
            }

            //set today values
            // let today = resolveDay(Date.now())
            // if(summaryResponse.hasOwnProperty(today)){
            //     this.chart1Data.todayValue = summaryResponse[today];
            // }
            // this.chart2Data.todayValue = sum;
            // if(this.chart2Data.boxData.hasOwnProperty(today)){
            //     this.chart2Data.todayValue = this.chart2Data.boxData[today];
            // }
            // if(this.chart4Data.hasOwnProperty("boxData")){
            //     this.chart4Data.todayValue = this.chart4Data.boxData[0]
            // }

            //set table data
        
            this.tableOptions.items[0][0]=(this.chart1Data.boxData)
            this.tableOptions.items[1][0]=(this.chart3Data.boxData)
            this.tableOptions.items[2][0]=({WTD:this.chart2Data.boxData[0], Percentile:this.chart2Data.boxData[1]})
            this.tableOptions.items[3][0]=({WTD:this.chart4Data.boxData[0], Percentile:this.chart4Data.boxData[1]})

            this.$emit("getRawData", [this.chart1Data.boxData, this.chart2Data.boxData, this.chart3Data.boxData, this.chart4Data.boxData], this.ID)
        },
        onExport(){
            exportChartWithText(this.$store.getters.getCharts(),this.exportData, {
                    type: 'application/pdf',
                    filename: 'ReturnAndWinRate'
            }, true);
        },
        getComponentData(componentData){
            let response = []
            for(let component in componentData){
                let componentDataArray = Object.keys(componentData[component]).map(key => {
                    return componentData[component][key];
                })
                let positiveCount = componentDataArray.filter(x=>x > 0).length;
                let otherCount = componentDataArray.filter(x=>x != 0).length;
                let result = positiveCount / otherCount;
                response.push(result*100)
            }
            return response;
        },
        getSVG(svg){
            this.$emit("getSVG", svg , this.ID);
        }
    }
}
</script>
<template>
    <div id="chart">
        <div class="col-12">
                <div :id="'container'+chartID"></div>
        </div>
    </div>
</template>

<script>
import Highcharts, { arrayMax } from 'highcharts'
import highchartsVue from 'highcharts-vue'
import histogram from 'highcharts/modules/histogram-bellcurve'
histogram(Highcharts)

//exporting
import exporting from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/offline-exporting";
exporting(Highcharts);
offlineExporting(Highcharts);

export default {
    data(){
        let that = this
        let exportingConfig = {
            filename: "Histogram",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                title:{text:""},
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                
            },
            menuItemDefinitions: {
            toggleBox: {
                onclick: function () {
                   this.options.toggleBox = !this.options.toggleBox
                },
                text: 'Toggle Box'
            }
        },
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG", "separator", "toggleBox"]
                }
            },
            fallbackToExportServer: false,
        };
        return{
            chart:{},
             options:  {
                toggleBox:false,
                credits:{enabled:false},
                exporting: exportingConfig,
                title: {
                    text: 'Highcharts Histogram'
                },

                 

                xAxis: [{
                    title: { text: '' },
                    alignTicks: false
                }, {
                    title: { text: '' },
                    alignTicks: false,
                    opposite: false,
                    labels:{
                        formatter: function (){
                            return this.value + "%";
                        }
                    }
                }],

                yAxis: [{
                    title: { text: '' }
                }, {
                    title: { text: '' },
                    opposite: false,
                    
                }],

                plotOptions: {
                    histogram: {
                        accessibility: {
                            pointDescriptionFormatter: function (point) {
                                var ix = point.index + 1,
                                    x1 = point.x.toFixed(3),
                                    x2 = point.x2.toFixed(3),
                                    val = point.y;
                                return ix + '. ' + x1 + ' to ' + x2 + ', ' + val + '.';
                            }
                        },
                        tooltip:{
                            useHTML:true,
                            pointFormatter: function (point){
                                let title = this.series.chart.options.title.text
                                title = title.toLowerCase()
                                let x = this.x.toFixed(2)
                                let x2 = this.x2.toFixed(2)
                                if(title.includes("component")){
                                    x = this.x
                                    x2 = this.x2
                                }
                                return `<span style="font-size: 10px">${x}% - ${x2}%</span><br/><span style="color:${this.color}">●</span> ${this.series.name} <b>${this.y}</b><br/>`
                            }
                        }
                    }
                },

                series: [{
                    showInLegend:false,
                    name: 'histogram',
                    type: 'histogram',
                    xAxis: 1,
                    yAxis: 1,
                    baseSeries: 's1',
                    zIndex: -1,
                    binWidth:1/20,
                    zoneAxis: 'x',
                    
                   
                }, {
                    name: 'scatterData',
                    type: 'scatter',
                    data: [],
                    id: 's1',
                    marker: {
                        radius: 1
                    },
                    visible:false,
                    showInLegend:false,
                }]
             },
        }
    },
    props: {
        winRate: {
            type: Boolean,
            default: false
        },
        daily: {
            type: Boolean,
            default: false
        },
        chartID:{
            type: String,
            default: "0"
        },
        title:{
            type:String,
            default:"title"
        },
        yAxis:{
            type:String,
            default:""
        },
        xAxis:{
            type:String,
            default:""
        },
        data:{
            type:Object,
            default:()=>{return {}}
        }
    },
        mounted(){
            let newOptions = this.options;
            newOptions.title.text = this.title;
            newOptions.xAxis[1].title.text = this.xAxis;
            newOptions.yAxis[1].title.text = this.yAxis;
            newOptions.series[1].data = this.data.data;
            if(this.winRate){
                let binNumber = 20;
                newOptions.series[0]["binsNumber"] = binNumber
                newOptions.series[0]["binWidth"] = 100/binNumber
                newOptions.xAxis[1].min = 0;
                newOptions.xAxis[1].max = 100;
            }
            let boxHeight = 30;
            let plotLeft = 50;
            let plotTop = 300;
            if(this.daily){
                boxHeight = 120;
            }
            if(this.chartID == 1){
                plotTop = 280;
                plotLeft = 50;
            }else if (this.chartID == 2){
                plotTop = 250;
                plotLeft = -250;
            }else if (this.chartID == 3){
                plotTop = 40;
                plotLeft = -250;
            }

            //set colour
            if(this.data.hasOwnProperty("todayValue")){
           
                let mainColor = "#7CB5EC"
                let todayColor = "#2181db"
                let zones = [{
                        value: 0,
                        color:mainColor
                    }, {
                        value: 0,
                        color:todayColor
                    },{
                        
                        color:mainColor
                    }]
                let decrement = 0.05
                if(this.winRate){
                    decrement = 5;
                }
                zones[1].value = this.data.todayValue
                zones[0].value = this.data.todayValue - decrement
                newOptions.series[0].zones = zones;
            }

            // newOptions.chart = {
            //     events:{
            //         load: function(){
            //             return
            //             if(this.options.toggleBox){return;}
            //             var point = this.series[0].data[8],
            //             text = this.renderer.text(
            //                 'Max',
            //                 point.plotX + this.plotLeft - plotLeft,
            //                 point.plotY + this.plotTop - plotTop
            //             ).attr({
            //                 zIndex: 5
            //             }).add(),
            //             box = text.getBBox();

            //             this.renderer.rect(box.x - 5, box.y - 5, box.width + 110, box.height + boxHeight, 5)
            //                 .attr({
            //                     fill: '#FFFFEF',
            //                     stroke: 'gray',
            //                     'stroke-width': 1,
            //                     zIndex: 4
            //                 })
            //                 .add();
            //         }
                    
            //     }
                
            // }

            this.chart = Highcharts.chart("container"+this.chartID,newOptions)

            this.$store.commit('addToCharts',{chart:this.chart, chartID:this.chartID});

            this.$emit("getSVG", this.chart.getSVG(), this.chartID);

        },
        methods:{

        }
}
</script>